<template>
  <div class="config-container">
    <div class="config-item">
      <p>配置转换器：天煞孤星</p>
      <p>
        已淘汰，虽然可以使用，但如有问题，<br>请换用<a href="/game-scheme/config"><b>[标准配置转换器]</b></a>
      </p>
    </div>
    <div class="config-item">
      <input type="file" id="config" name="config" @change="convertConfig">
    </div>
  </div>
</template>

<script>
const ExcelJS = require('exceljs');

export default {
  name: 'GroundhogLife',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  created() {},
  methods: {
    // Excel 转 JSON
    convertConfig(event) {
      const file = event.target.files[0];
      if (file.name.search(/.xlsx$/) < 0 && file.name.search(/.xls$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then((excel) => {
          // 常数
          let count = 0;
          let isFirst = true;
          let headers = [];
          const configSheet = excel.worksheets[0];
          let result = '{';
          result += this.keyToValue(configSheet, '常数', 1);
          result += '\n}';
          console.info(result);
          result = JSON.parse(result);

          excel.worksheets.map((sheet) => {
            if (sheet.name === '常数') {
              return sheet;
            }

            count = 0;
            isFirst = true;
            headers = [];
            sheet.eachRow((row) => {
              if (isFirst) {
                isFirst = false;
                result[sheet.name] = [];
                row.values.map((data) => {
                  headers.push(this.getText(data));
                  return data;
                });
                return;
              }

              let i = 1;
              result[sheet.name][count] = {};
              row.values.map((data) => {
                if (sheet.name === '事件' && headers[i - 1] === '选项') {
                  const choices = this.getText(row.values[i]);
                  if (choices !== '') {
                    result[sheet.name][count][headers[i - 1]] = choices.split('\\n');
                  } else {
                    result[sheet.name][count][headers[i - 1]] = [];
                  }
                } else {
                  result[sheet.name][count][headers[i - 1]] = this.getText(row.values[i]);
                }
                i++;
                return data;
              });
              count++;
            });

            return sheet;
          });

          console.info(result);
          result = JSON.stringify(result);

          // 创建隐藏的可下载链接
          const eleLink = document.createElement('a');
          eleLink.download = 'config.json';
          eleLink.style.display = 'none';
          // 字符内容转变成blob地址
          const blob = new Blob([result]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击并移除
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
        });
      };
      reader.readAsArrayBuffer(file);
      return true;
    },

    // 获取实际 Text
    getText(cell) {
      if (cell === undefined) {
        return '';
      }
      let text = '';
      if (cell.result) {
        return cell.result;
      }
      if (cell.richText) {
        cell.richText.map((value) => {
          if (value.text === '\n' || value.text === '\r' || value.text === '\r\n') {
            text += '\\n';
          } else {
            text += value.text.replaceAll('\n', '\\n');
          }
          return value;
        });
        return text;
      }
      if (typeof cell === 'string') {
        return cell.replaceAll('\n', '\\n');
      }
      return cell;
    },

    // excel k:v 转化器
    keyToValue(configSheet, parentName, indexFix) {
      let result = '';
      configSheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          result += `\n"${parentName}": {`;
        }

        let i = 1;
        result += `"${this.getText(row.values[i++])}": "${this.getText(row.values[i + indexFix])}"`;
        if (configSheet.getRow(rowNumber + 1).values.length) result += ', ';
        else result += '}';
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-container {
  display: flex;
  justify-content: flex-start;
}
.config-item {
  flex: 0 1 20%;
  a {
    color: cornflowerblue;
    text-decoration: underline;
  }
  a:hover {
    color: cornflowerblue;
    text-decoration: none;
  }
}

.board-container {
  width: 470px;
  height: 470px; /* height given for illustration */
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.board-item {
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid black;
  margin: 4px;
  flex: 0 1 calc(20% - 10px);
}

a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

/* 模态框 */
.overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: relative;
    width: 600px;
    max-width: 80%;
    background: white;
    border-radius: 8px;
    padding: 1em 2em;
    .modal-close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: grey;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
.overlay:target {
  visibility: visible;
}
</style>
